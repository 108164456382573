import React, { useState, useEffect } from "react";
import styles from "./tablefeedback.module.css";
import { Select } from "antd";
import ReactPaginate from "react-paginate";
import { userRequest } from "../../../requestMethod";
import star from "../../../assets/images/star.png";
import { useFeedback } from "../../../FeedbackContext";
import moment from "moment";
import axios from "axios";

function generateStarImages(rating) {
  const starImages = [];
  for (let i = 1; i <= rating; i++) {
    starImages.push(<img src={star} alt="star" key={i} />);
  }
  return starImages;
}

function TableFeedback() {
  const ratingOption = [
    { value: "all", label: "All Ratings" }, // Add an "All" option
    { value: 5, label: "5 Star" },
    { value: 4, label: "4 Star" },
    { value: 3, label: "3 Star" },
    { value: 2, label: "2 Star" },
    { value: 1, label: "1 Star" },
  ];

  const itemsPerPageOptions = [
    { value: 5, label: "5 / page" },
    { value: 10, label: "10 / page" },
    { value: 20, label: "20 / page" },
    { value: 50, label: "50 / page" }
  ];

  const [feedbackData, setFeedbackData] = useState([]);
  const [selectedRating, setSelectedRating] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [sortField, setSortField] = useState('savedTime');
  const [sortOrder, setSortOrder] = useState('asc');
  const [itemsPerPage, setItemsPerPage] = useState(20); 

  const {
    selectedClients,
    selectedDrivers,
    selectedTrips,
    startDate,
    endDate,
    currentData
  } = useFeedback();
  console.log(selectedClients, 'selectedClientsAreHere');


  const fetchData = async (newPage, itemsPerPage, sortOrder) => {
    setCurrentPage(newPage);
  
    try {
      // Create the clients, drivers, and tripTypes URL parameters based on the respective arrays
      const clientsUrl = createUrl(selectedClients, 'clients');
      const driversUrl = createUrl(selectedDrivers, 'drivers');
      const tripsUrl = createUrl(selectedTrips, 'tripTypes');
  
      // Determine the URL based on the selectedRating
      const baseUrl = selectedRating === "all"
        ? `https://feedback-api.preimo.com/api/feedback/feedbackTableData`
        : `https://feedback-api.preimo.com/api/feedback/feedbackTableData?rating=${selectedRating}`;
  
      // Fetch data using axios with the dynamically created URL
      const response = await axios.get(
				`${baseUrl}?page=${
					newPage ? newPage : currentPage
				}&pageSize=${itemsPerPage}${clientsUrl}${driversUrl}${tripsUrl}&startDate=${startDate}&endDate=${endDate}&dateRange=${currentData}`
			);
  
      // Set the fetched data and total pages
      setFeedbackData(response.data.feedbackData);
      setTotalPage(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching feedback data:", error);
    }
  };
  
  function createUrl(array, paramName) {
    if (!Array.isArray(array) || array.length === 0) {
      return '';
    }
    const urlParameters = array.map(element => `${paramName}=${element}`).join('&');
  
    return `&${urlParameters}`; 
  }
  

  useEffect(() => {
    fetchData(1,itemsPerPage,sortOrder);
  }, [selectedRating,selectedClients,selectedDrivers,selectedTrips,startDate,endDate, currentData]);

  const totalItems = feedbackData.length;
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // const currentData = feedbackData.slice(startIndex, endIndex);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    fetchData(selectedPage.selected+1,itemsPerPage,sortOrder)
  };

  const handleChange = (value) => {
    setSelectedRating(value);
  };

    // Handler for changing the number of items per page
    const handleItemsPerPageChange = (value) => {
      debugger;
      setItemsPerPage(value);
      fetchData(1,value,sortOrder);  // Fetch first page data with the new items per page value
    };

  return (
    <>
      <div className={styles.tableHeder}>
        <p>Latest Feedback</p>
        <div>
        <Select
          defaultValue="All Ratings"
          style={{
            width: 120,
            fontFamily: "Montserrat",
            fontWeight:500
          }}
          onChange={handleChange}
          options={ratingOption}
        />
        <Select defaultValue={20} onChange={handleItemsPerPageChange} style={{ width: 120, margin: "0px 15px" }}>
          {itemsPerPageOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
          ))}
        </Select>
        </div>
        
      </div>
      <div className={styles.tableMain}>
        <table>
          <thead>
            <tr>
              <th>Chauffeur Name</th>
              <th>Clients</th>
              <th>Passenger</th>
              <th>Trip type</th>
              <th>Date & Time</th>
              <th>Geo location</th>
              <th>Ratings</th>
            </tr>
          </thead>
          <tbody>
            {feedbackData.map((row, index) => (
              <tr key={index}>
                <td>{row.driverName}</td>
                <td>{row.clientName}</td>
                <td>{row.passengerName}</td>
                <td>{row.tripType}</td>
                <td>
                  <span>
                    Date: {moment(row.savedAtTime).local().format("YYYY-MM-DD")}
                  </span>
                  <br />
                  <span>
                    Time: {moment(row.savedAtTime).local().format("HH:mm")}
                  </span>
                </td>

                <td>{row.geolocation}</td>
                <td>{generateStarImages(row.ratings)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.pagination}>
        <ReactPaginate
          pageCount={totalPage}
          forcePage={currentPage - 1}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          activeClassName={styles.activePage}
        />
      </div>
    </>
  );
}

export default TableFeedback;
