import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.css";
import Feedback from "./Feedback/Feedback";
import { Menu, Dropdown, Button, Select, DatePicker } from "antd";
import SelectDriver from "../../components/SelectDriver";
import SelectClient from "../../components/SelectClient";
import SelectTrip from "../../components/SelectTrip";
import { useFeedback } from "../../FeedbackContext";
import ShowOptionsDriver from "../../components/showOptionDataDriver";
import ShowOptions from "../../components/showOptionsDataClient";
import ShowOptionsTrip from "../../components/showOptionTripOption";
import { DownOutlined } from "@ant-design/icons";

import moment from "moment";
const { RangePicker } = DatePicker;

function Dashboard() {
  const {
    selectedClients,
    setSelectedClients,
    selectedDrivers,
    setSelectedDrivers,
    selectedTrips,
    setSelectedTrips,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useFeedback();

  const menuItems = [{ label: "Overall Feedback", state: "feedback" }];

  const [activeMenuItem, setActiveMenuItem] = useState("feedback");
  const [driverOptions, setDriverOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedCOptions, setClientSelectedOption] = useState([]);
  const [selectedTOptions, setSelectedTOptions] = useState([]);
  const [showOption, setShowOption] = useState([]);
  const [showOption1, setShowOption1] = useState([]);
  const [startDates, setStartDates] = useState();
  const [endDates, setEndDates] = useState();
  const [showOption2, setShowOption2] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [showSelection, setShowSelection] = useState("Overall");

  const handleDashboardMenu = (menu) => {
    setActiveMenuItem(menu);
  };
  useEffect(() => {
    if (startDates && endDates) {
      setStartDate(startDates);
      setEndDate(endDates);
    }
  }, [endDates, startDates]);

  const handleRemoveSelectedOption = (selectedOption) => {
    const finalData = selectedOptions.filter(
      (option) => option !== selectedOption
    );
    setSelectedOptions(finalData);
    setSelectedDrivers(finalData);
    setShowOption1(finalData);
  };

  const handleRemoveOption = (optionToRemove) => {
    const finalData = selectedCOptions.filter(
      (option) => option !== optionToRemove
    );
    setClientSelectedOption(finalData);
    setSelectedClients(finalData);
    setShowOption(finalData);
  };

  const handleRemoveTripSelectedOption = (selectedOption) => {
    const finalData = selectedTOptions.filter(
      (option) => option !== selectedOption
    );
    setSelectedTOptions(finalData);
    setSelectedTrips(finalData);
    setShowOption2(finalData);
  };

  const handleChange = (dates) => {
    debugger;
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDates(start.format("YYYY-MM-DD"));
      setEndDates(end.format("YYYY-MM-DD"));
    } else {
      setStartDates(null);
      setEndDates(null);
    }
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setShowSelection(selectedValue);
    if (selectedValue == "Overall") {
      window.location.reload();
      return;
    }
    if (selectedValue === "Custom Date Range") {
      setShowDate(true);
    } else if ("This Week") {
      setShowDate(false);
      let start = moment().startOf("month");
      let end = moment().endOf("month");
      setStartDate(start);
      setEndDate(end);
    } else if ("This Month") {
      setShowDate(false);
      let start = moment().startOf("month");
      let end = moment().endOf("month");
      setStartDate(start);
      setEndDate(end);
    }
  };
  const handleDateSelection = (value) => {
    debugger;
    let start, end;

    switch (value) {
      case "thisWeek":
        start = moment().startOf("week");
        end = moment().endOf("week");
        break;
      case "thisMonth":
        start = moment().startOf("month");
        end = moment().endOf("month");
        break;
      case "custom":
        setShowDate(true);

        break;
      default:
        start = null;
        end = null;
        break;
    }

    // Clearing the date if not 'thisWeek' or 'thisMonth'
    if (start && end) {
      setStartDates(start.format("YYYY-MM-DD"));
      setEndDates(end.format("YYYY-MM-DD"));
    } else {
      setStartDates(null);
      setEndDates(null);
    }
  };

  const menu = (
    <Menu onClick={(e) => handleDateSelection(e.key)}>
      <Menu.Item key="thisWeek">This week</Menu.Item>
      <Menu.Item key="thisMonth">This month</Menu.Item>
      <Menu.Item key="custom">Custom Date Range</Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.dashboardMain}>
      <div className={styles.dashboardHeader}>
        <p>Feedback dashboard</p>
      </div>
      <div className={styles.menuContaier}>
        <div className={styles.dashboardMenu}>
          {menuItems.map((item) => (
            <div
              key={item.state}
              className={
                activeMenuItem === item.state
                  ? `${styles.active} ${styles.menuItem}`
                  : styles.menuItem
              }
              onClick={() => handleDashboardMenu(item.state)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>

        <div className={styles.dashboardFilter}>
          <SelectClient
            setClientOptions={setClientOptions}
            clientOptions={clientOptions}
            setSelectedOptions={setClientSelectedOption}
            selectedOptions={selectedCOptions}
            selectedClients={selectedClients}
            setSelectedClients={setSelectedClients}
            setShowOption={setShowOption}
            showOption={showOption}
          />
          <SelectDriver
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            selectedDrivers={selectedDrivers}
            setSelectedDrivers={setSelectedDrivers}
            setDriverOptions={setDriverOptions}
            driverOptions={driverOptions}
            setShowOption1={setShowOption1}
            showOption1={showOption1}
          />
          <SelectTrip
            selectedTrips={selectedTrips}
            setSelectedTrips={setSelectedTrips}
            selectedOptions={selectedTOptions}
            setSelectedOptions={setSelectedTOptions}
            setShowOption2={setShowOption2}
          />
          <select
            className={styles.selectCss}
            value={showSelection}
            onChange={handleSelectChange}
          >
            <option value="" disabled>
              Select Period
            </option>
            <option value="Overall">Overall period</option>
            <option value="This Month">This Month</option>
            <option value="This Week">This Week</option>
            <option value="Custom Date Range">Custom Date</option>
          </select>

          <RangePicker
            onOpenChange={() => {
              setShowSelection("");
              setShowDate(false);
            }}
       
            open={showDate}
            style={{
              width: 230,
              height: "4.2dvh",
              visibility: "hidden",
            }}
            onChange={handleChange}
            value={
              startDates && endDates
                ? [moment(startDates), moment(endDates)]
                : []
            }
          />
        </div>
      </div>
      <div>
        <ShowOptionsDriver
          selectedOptions={selectedOptions}
          driverOptions={driverOptions}
          handleRemoveSelectedOption={handleRemoveSelectedOption}
          showOption1={showOption1}
        />

        {clientOptions && clientOptions.length > 0 && (
          <ShowOptions
            selectedOptions={selectedCOptions}
            setSelectedOptions={setClientOptions}
            clientOptions={clientOptions}
            setClientOptions={setClientOptions}
            setSelectedClients={setSelectedClients}
            handleRemoveOption={handleRemoveOption}
            setShowOption={setShowOption}
            showOption={showOption}
          />
        )}
        <ShowOptionsTrip
          selectedOptions={selectedTOptions}
          selectedTrips={selectedTrips}
          handleRemoveTripSelectedOption={handleRemoveTripSelectedOption}
          showOption2={showOption2}
        />
      </div>

      {activeMenuItem === "feedback" && (
        <Feedback startDate={startDates} endDate={endDates} />
      )}
    </div>
  );
}

export default Dashboard;
