/**
 * @author Vishal Talukar
 */

/**
 * component to display FeedBack chart
 */

import React, { useState, useEffect } from "react";
import { Area } from "@ant-design/plots";
import { Line } from "@ant-design/plots";
import { BASE_URL } from "../requestMethod";
import { useFeedback } from "../FeedbackContext";

const FeedbackChart = ({ count }) => {
  const {
    selectedClients,
    selectedDrivers,
    selectedTrips,
    startDate,
    endDate,
    currentData
  } = useFeedback();

  // display "date": "2001-11-22",
  // "count": 7 when no data is found.
  const [data, setData] = useState([
    {
      date: "2001-11-22",
      count: 7,
    },
  ]);

  const fetchData = async (startDate, endDate) => {
    fetch(`${BASE_URL}feedback/feedbackChart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clients: selectedClients,
        drivers: selectedDrivers,
        tripTypes: selectedTrips,
        startDate: startDate,
        endDate: endDate,
        dateRange:currentData
      }),
    })
      .then((response) => response.json())
      .then((json) => setData(json.data))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };

  const config = {
    data,
    xField: "date",
    yField: "count",
    xAxis: {
      range: [0, 1],
      tickCount: 5,
      line: {
        style: {
          stroke: "#333842", // Color of the axis border
          lineWidth: 4, // Thickness of the axis border
        },
      },
    },
    yAxis: {
      // In case you have any other configurations, they should be included here
      line: {
        style: {
          stroke: "#333842", // Color of the axis border
          lineWidth: 4, // Thickness of the axis border
        },
      },
    },
    areaStyle: {
      fill: "l(90) 0:#ffffff 1:#ececec",
    },
    point: {
      size: 4,
      // Simply pass the shape type as a string
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    smooth: true,
    // To create a gradient color below the line, use color callback
  };

  useEffect(() => {
    fetchData(startDate, endDate);
  }, [selectedClients, selectedDrivers, selectedTrips, startDate, endDate, currentData]);

  return (
    <>
      <div
        style={{
          color: "#333842",
          fontSize: 24,
          fontFamily: "Montserrat",
          fontWeight: "600",
          wordWrap: "break-word",
          paddingBottom: "15px",
        }}
      >
        No of feedback: {count}
      </div>

      <Line {...config} height={510} width={850} />
    </>
  );
};
export default FeedbackChart;
