import { FeedbackProvider } from "./FeedbackContext";
import "./App.css";
import Navbar from "./pages/Navbar/Navbar";

function App() {
  return (
    <FeedbackProvider>
    <div>
      <Navbar />
    </div>
    </FeedbackProvider>
  );
}

export default App;
