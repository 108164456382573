import React, { useEffect, useState } from "react";
import { Button, Checkbox, Divider, Modal } from "antd";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import { userRequest } from "../requestMethod";
import {RxCross2} from 'react-icons/rx';
import ShowOptionsDriver from "./showOptionDataDriver";

const SelectDriver = ({setSelectedDrivers,selectedOptions,setSelectedOptions,driverOptions ,setDriverOptions, setShowOption1}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState("");
 
  

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(driverOptions.map((driver) => driver._id));
    }
    setSelectAll(!selectAll);
  };

  const handleApplyFilter = () => {
    setIsModalOpen (false);
    setSelectedDrivers(selectedOptions);
    setShowOption1(selectedOptions)
  };

  useEffect(() => {
    userRequest
      .get("/driver/getAllDrivers")
      .then((response) => {
        setDriverOptions(response.data.drivers);
      })
      .catch((error) => {
        console.error("Error fetching drivers:", error);
      });
  }, []);

  const filteredOptions = driverOptions?.filter((driver) =>
    driver.driverName.toLowerCase().includes(searchText.toLowerCase())
  );

  const styles = {
    filterContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
    },
    searchBox: {
      display: "flex",
      width: "100%",
    },
    searchInputContainer: {
      position: "relative",
      width: "100%",
    },
    searchInput: {
      height: "3rem",
      outline: "none",
      border: "1px solid #DCDEE5",
      width: "100%",
      borderRadius: "5px",
      paddingLeft: "40px",
      boxSizing: "border-box",
    },
    searchIcon: {
      position: "absolute",
      top: "50%",
      left: "15px",
      transform: "translateY(-50%)",
    },
    selectAllCheckbox: {
      marginTop: "2vh",
      paddingLeft: "2%",
    },
    chekboxStyle: {
      width: "20px",
      height: "20px",
    },
    labelStyle: {
      fontSize: "1.1rem",
      paddingLeft: "2%",
    },
    optionContainer: {
      marginTop: "1vh",
    },
    ulStyle: {
      display: "flex",
      flexDirection: "column",
      listStyleType: "none",
      marginBlockStart: "0",
      marginBlockEnd: "0",
      marginInlineStart: "0",
      marginInlineEnd: "0",
      paddingInlineStart: "2%",
    },
    liStyle: {
      padding: "1vh 0",
      fontSize: "1.1rem",
    },
    buttonStyle: {
      width: "100%",
      padding: "1.8vh 0",
      backgroundColor: "#EEC858",
      border: "none",
      borderRadius:"10px",
      marginTop:"10px"
    },
    buttonStyleOne: {
      // height:'3vh'
    },
    selectedOptionChip: {
      display: "inline-flex",
      background: "rgb(238, 200, 88)",
      color: "black",
      padding: "4px 8px",
      borderRadius: "20px",
      margin: "10px",
    },
    removeOption: {
      marginLeft: "10px",
      cursor: "pointer",
      fontSize:'15px',
        },
  }

  return (
    <>
      <Button type="white" onClick={showModal} style={styles.buttonStyleOne}>
        Select Driver <DownOutlined />
      </Button>
      <Modal
        visible={isModalOpen}
        width={300}
        onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        <div style={styles.filterContainer}>
          <div style={styles.searchBox}>
          <div style={styles.searchInputContainer}>
              <div style={styles.searchIcon}>
                <SearchOutlined />
              </div>
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={styles.searchInput}
            />
            </div>
          </div>
          <div style={styles.selectAllCheckbox}>
            <Checkbox
              checked={selectAll}
              onChange={toggleSelectAll}
              style={styles.chekboxStyle}
            />
            <label style={styles.labelStyle}>Select All</label>
          </div>
          <Divider style={{ margin: "5px 0" }} />
          <div style={styles.optionContainer}>
            <ul style={styles.ulStyle}>
              {filteredOptions.map((driver) => (
                <li key={driver._id} style={styles.liStyle}>
                  <Checkbox
                    checked={selectedOptions.includes(driver._id)}
                    onChange={() => toggleOption(driver._id)}
                    style={styles.chekboxStyle}
                  />
                  <label style={styles.labelStyle}>{driver.driverName}</label>
                </li>
              ))}
            </ul>
          </div>
          <button onClick={handleApplyFilter} style={styles.buttonStyle}>
            Apply Filter
          </button>
        </div>
      </Modal>
      {/* <div>
          <ul>
            {selectedOptions.map((option) => (
              <li key={option} style={styles.selectedOptionChip}>
                {driverOptions.find((driver) => driver._id === option)?.driverName}
                <span onClick={() => handleRemoveSelectedOption(option)} style={styles.removeOption}>
                 <RxCross2/>
                </span>
              </li>
            ))}
          </ul>
        </div> */}
 
    </>
  );
};

export default SelectDriver;
