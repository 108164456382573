import React, { useEffect, useState } from "react";
import styles from "./feedback.module.css";
import growthDown from "../../../assets/images/growthDown.png";
import growthUp from "../../../assets/images/growthUp.png";
import star from "../../../assets/images/star.png";
import twoStar from "../../../assets/images/twoStar.png";
import threeStar from "../../../assets/images/threeStar.png";
import fourStar from "../../../assets/images/fourStar.png";
import fiveStar from "../../../assets/images/fiveStar.png";
import warning from "../../../assets/images/Warning.png";
import ChartComponent from "../Chart/ChartComponent";
import TableFeedback from "../TableFeedback/TableFeedback";
import { userRequest } from "../../../requestMethod";
import { useFeedback } from "../../../FeedbackContext";
import BarGraph from "./graph";
import LineGraph from "./lineGraph";
import axios from "axios";
import FeedbackChart from "../../../components/FeedbackChart";
import { calculateAvgRatings } from "../../../utils/calculateAvgRatings";
import StartRating from "../../../components/StartRating";
import AverageChartData from "../../../components/AverageChartData";
function Feedback() {
  const {
    selectedClients,
    selectedDrivers,
    selectedTrips,
    currentData,
    startDate,
    endDate
  } = useFeedback();
  const [feedbackData, setFeedbackData] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCategoryData, setSelectedCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeCard, setActiveCard] = useState(false);
  const [isRatingCardClicked, setIsRatingCardClicked] = useState(false);
  const [showChart, setShowChart] = useState(false);

  const handleCategoryClick = async (category) => {
    try {
      const response = await userRequest.post("/feedback/getAllFeedBackData", {
        category: category,
        clients: selectedClients,
        drivers: selectedDrivers,
        tripTypes: selectedTrips,
        startDate: startDate,
        endDate: endDate,
        dateRange:currentData
      });
      setActiveCard(true);
      setSelectedCategoryData(response.data);
      setSelectedCategory(category);
      setSelectedCard((prevSelectedCard) => {
        if (prevSelectedCard?.category === category) {
          return { category: null, data: [] };
        } else {
          return { category: category, data: response.data };
        }
      });
    } catch (error) {
      console.error("Error fetching selected category data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await userRequest.post("/feedback/feedbackCardData", {
        clients: selectedClients,
        drivers: selectedDrivers,
        tripTypes: selectedTrips,
        startDate: startDate,
        endDate: endDate,
        dateRange:currentData
      });

      setFeedbackData(response.data.feedbackStats);
    } catch (error) {
      console.error("Error fetching feedback data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedClients, selectedDrivers, selectedTrips, startDate,currentData ,endDate]);

  const redBackground = styles.redBackground;

  //card rendering started

  console.log(feedbackData, "allFeedbackdataISHere");

  const renderRatingCard = (_id, ratingImage) => {
    const ratingData = feedbackData?.ratingCountsCurrentMonth?.find(
      (data) => data._id === _id
    );
    const count = ratingData?.count || 0;
    // setTotalRating(totalRating + count);

    const totalFeedbacks = feedbackData.totalFeedbacksCurrentMonth || 0;
    const growth = ratingData?.growth || 0;
    const cardStyles =
      growth < 0 ? `${styles.singleCard} ${redBackground}` : styles.singleCard;
    return (
      <>
        <div className={{
          display: "flex", flexDirection: "column", textAlign: 'center', alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div className={cardStyles} onClick={()=>setIsRatingCardClicked(true)}>
            <div className={styles.left}>
              <p>
                {((count / totalFeedbacks) * 100).toFixed(0)}%
                <span>({count})</span>
              </p>
            </div>

            <div className={styles.right}>
              <div className={styles.cardGrowth}>
                <img src={growth < 0 ? growthDown : growthUp} alt="" />
                <p>
                  {growth !== undefined ? Math.abs(growth).toFixed() : "N/A"}%
                </p>
              </div>
            </div>
          </div>
          <p style={{ display: "flex", alignItems: "center" }}>
            Trip Rating
            <img src={ratingImage} alt="" />
          </p>
        </div>
      </>
    );
  };

  const renderImprovementCard = (category, count, growthPercentage) => {
    const totalFeedbacks = feedbackData?.totalFeedbacksCurrentMonth || 0;
    const countCurrentMonth = count || 0;
    const growth = growthPercentage || 0;
    const cardStyles =
      growth < 0
        ? `${styles.singleCardTwo} ${redBackground}`
        : styles.singleCardTwo;
    const cardStyles1 = activeCard ? `${styles.activeSingleCardTwo}` : "";
    const isSelected = selectedCard?.category === category;
    const borderStyles = isSelected ? `${styles.selectedCardBorder}` : "";

    return (
      <div
        className={`${cardStyles} ${borderStyles}`}
        onClick={() => handleCategoryClick(category)}
      >
        <div className={styles.leftTwo}>
          <p>
            <img src={warning} alt="" />
            {((countCurrentMonth / totalFeedbacks) * 100).toFixed(0)}%
            <span>({countCurrentMonth})</span>
          </p>
          <p>{category}</p>
        </div>
        <div className={styles.rightTwo}>
          <div className={styles.cardGrowthTwo}>
            <img src={growth < 0 ? growthDown : growthUp} alt="" />
            <p>{growth !== undefined ? Math.abs(growth).toFixed() : "N/A"}%</p>
          </div>
        </div>
      </div>
    );
  };

  //card rendering ends

  return (
    <>
      <div className={styles.container}>
        <div className={styles.grid1} style={{cursor:'pointer'}}>
                    <div onClick={() => setShowChart(true)} className={styles.top_cards}>
            <div style={{ color: '#333842', cursor:'pointer', fontSize: 24, fontFamily: 'Montserrat', fontWeight: '500', wordWrap: 'break-word', width: "inherit" }}>
              {<StartRating ratings={feedbackData?.ratingCountsCurrentMonth} />}
            </div>

            <div className={styles.cardGrowth}>
              <img src={growthUp} alt="" />
              <p>
                {feedbackData.growthPercentage &&
                  feedbackData.growthPercentage.toFixed()}
                %
              </p>
            </div>
          </div>
          <div className={styles.top_cards}>
            <div onClick={() => setShowChart(false)} style={{cursor:'pointer', color: '#333842', fontSize: 24, fontFamily: 'Montserrat', fontWeight: '500', wordWrap: 'break-word', width: "inherit" }}>
              {feedbackData.totalFeedbacksCurrentMonth}</div>
            <p style={{ color: '#696973', fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '400', wordWrap: 'break-word', }}>No of Feedbacks</p>


            <div className={styles.cardGrowth}>
              <img src={growthUp} alt="" />
              <p>
                {feedbackData.growthPercentage &&
                  feedbackData.growthPercentage.toFixed()}
                %
              </p>
            </div>
          </div>


        </div>
        <div className={styles.grid2}>
          {!showChart && <FeedbackChart count={feedbackData.totalFeedbacksCurrentMonth} />}
          {showChart && <AverageChartData/>}
        </div>
      </div>

      <div className={styles.feedbackMain}>
        <div className={styles.feedbackCards}>
          <div className={styles.rowOne1}>
            {renderRatingCard(5, fiveStar)}
            {renderRatingCard(4, fourStar)}

            {renderRatingCard(3, threeStar)}
            {renderRatingCard(2, twoStar)}
            {renderRatingCard(1, star)}


          </div>

          <div className={styles.chartContainer}>
        <ChartComponent isRatingCardClicked={isRatingCardClicked} />
        </div>
          <div className={styles.rowOne}>
            {feedbackData?.improvementStats?.map((stat) =>
              renderImprovementCard(
                stat.category,
                stat.countCurrentMonth,
                stat.growthPercentage
              )
            )}
          </div>
        </div>
        <div className={styles.chartContainer}>
        {selectedCard?.category && (
          <LineGraph selectedCard={selectedCard} />
        )}
        </div>

        <div className={styles.tableContainer}>
          <TableFeedback/>
        </div>
      </div>
    </>
  );
}

export default Feedback;
