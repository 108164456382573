import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import RouterPage from "../../components/RouterPage";
import logo from "../../assets/images/Top.png";
import leftArrow from "../../assets/images/leftArrow.png";
import dashboard from "../../assets/images/dasboard.png";
import driver from "../../assets/images/driver.png";
import client from "../../assets/images/client.png";
import vendor from "../../assets/images/vendor.png";
import vehicle from "../../assets/images/vehicle.png";
import booking from "../../assets/images/booking.png";

function Navbar() {
  const [isExpanded, setIsExpanded] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.mainContainer}>
        {isExpanded ? (
          <div className={styles.navbarMain}>
            <div className={styles.navbarHeader}>
              <img src={logo} className= {styles.imageContainer} alt="" onClick={() => navigate("/")} />
            </div>
            <div className={styles.menuContainer}>
              <MenuItem
                to="/"
                icon={dashboard}
                activeIcon={dashboard}
                label="Dashboard"
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
              {/* <MenuItem
                to="/"
                icon={booking}
                activeIcon={booking}
                label="Bookings"
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
              <MenuItem
                to="/"
                icon={client}
                activeIcon={client}
                label="Client"
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
              <MenuItem
                to="/"
                icon={driver}
                activeIcon={driver}
                label="Driver"
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
              <MenuItem
                to="/"
                icon={vehicle}
                activeIcon={vehicle}
                label="Vehicle"
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
              <MenuItem
                to="/"
                icon={vendor}
                activeIcon={vendor}
                label="Vendor"
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              /> */}
            </div>
          </div>
        ) : (
          <div className={`${styles.navbarMain} ${styles.navbarMainNExp}`}>
            <div className={styles.navbarHeaderNExp}>
              <img src={logo} alt="" onClick={() => navigate("/")} />
              <img src={leftArrow} alt="" />
            </div>
            <div className={styles.menuContainerTwo}>
              <MenuItem
                to="/"
                icon={dashboard}
                activeIcon={dashboard}
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
              <MenuItem
                to="/"
                icon={booking}
                activeIcon={booking}
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
              <MenuItem
                to="/"
                icon={client}
                activeIcon={client}
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
              <MenuItem
                to="/"
                icon={driver}
                activeIcon={driver}
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
              <MenuItem
                to="/"
                icon={vehicle}
                activeIcon={vehicle}
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
              <MenuItem
                to="/"
                icon={vendor}
                activeIcon={vendor}
                isActive={location.pathname === "/"}
                expanded={isExpanded}
              />
            </div>
          </div>
        )}
        <div className={isExpanded ? styles.routerPage : styles.routerPageNExp}>
          <RouterPage />
        </div>
      </div>
    </div>
  );
}

function MenuItem({ to, icon, activeIcon, label, isActive, expanded }) {
  return (
    <div className={`${styles.menu} ${isActive ? styles.activeMenuItem : ""}`}>
      <Link to={to}>
        <img src={isActive ? activeIcon : icon} alt="" />
        {expanded && <p style={{color:'white', textAlign:'center', alignItems:'center'}}>{label}</p>}
      </Link>
    </div>
  );
}

export default Navbar;
