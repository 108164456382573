// import React, { useEffect, useState } from "react";
// import styles from "./chart.module.css";
// import Chart from "react-google-charts";
// import { userRequest } from "../../../requestMethod";

// function ChartComponent() {

//   const [feedbackData, setFeedbackData] = useState([]);

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const response = await userRequest.get('/feedback/feedbackChartData');
//         setFeedbackData(response.data.feedbackStats);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     }
//     fetchData();
//   }, []);

//   const data = [
//     ["Task", "Hours per Day"],
//     ["Timeliness Issue", 11],
//     ["Amenities in the car", 2],
//     ["Chauffeur Issue", 2],
//     ["Cleanliness of the car", 7],
//   ];

//   const optionsFourStar = {
//     title: "4 Star",
//     titleTextStyle: {
//       fontSize: 20,
//     },
//     pieHole: 0.4,
//     is3D: false,
//   };
//   const optionsThreeStar = {
//     title: "3 Star",
//     titleTextStyle: {
//       fontSize: 20,
//     },
//     pieHole: 0.4,
//     is3D: false,
//   };
//   const optionsTwoStar = {
//     title: "2 Star",
//     titleTextStyle: {
//       fontSize: 20,
//     },
//     pieHole: 0.4,
//     is3D: false,
//   };
//   const optionsOneStar = {
//     title: "1 Star",
//     titleTextStyle: {
//       fontSize: 20,
//     },
//     pieHole: 0.4,
//     is3D: false,
//   };

//   return (
//     <div className={styles.chartMain}>
//       <div className={styles.chartRow}>
//         <div className={styles.singleChart}>
//           <Chart
//             chartType="PieChart"
//             width="100%"
//             height="250px"
//             data={data}
//             options={optionsFourStar}
//           />
//         </div>
//         <div className={styles.singleChart}>
//           <Chart
//             chartType="PieChart"
//             width="100%"
//             height="250px"
//             data={data}
//             options={optionsThreeStar}
//           />
//         </div>
//       </div>
//       <div className={`${styles.chartRow} ${styles.chartRowTwo}`}>
//         <div className={styles.singleChart}>
//           <Chart
//             chartType="PieChart"
//             width="100%"
//             height="250px"
//             data={data}
//             options={optionsTwoStar}
//           />
//         </div>
//         <div className={styles.singleChart}>
//           <Chart
//             chartType="PieChart"
//             width="100%"
//             height="250px"
//             data={data}
//             options={optionsOneStar}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ChartComponent;

import React, { useEffect, useState } from "react";
import styles from "./chart.module.css";
import Chart from "react-google-charts";
import { userRequest } from "../../../requestMethod";
import { useFeedback } from "../../../FeedbackContext";

function ChartComponent({ isRatingCardClicked }) 
 {
  const {
    selectedClients,
    selectedDrivers,
    selectedTrips,
    startDate,
    endDate,
  } = useFeedback();
  const [feedbackData, setFeedbackData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedClients && selectedDrivers && selectedTrips && startDate && endDate) {
      async function fetchData() {
        try {
          const response = await userRequest.post(
            `/feedback/feedbackChartData`, {
          clients: selectedClients,
          drivers: selectedDrivers,
          tripTypes: selectedTrips,
          startDate: startDate,
          endDate: endDate
            }
          );
          setFeedbackData(response.data.feedbackStats);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
  
      fetchData();
    }
  }, [selectedClients, selectedDrivers, selectedTrips, startDate, endDate]);
  

  const renderCharts = (feedbackData) => {

    if(!isRatingCardClicked){
      return;
    }
    const chartComponents = [];
    let rowCharts = [];

    for (const ratingData of feedbackData) {
      const data = [["Category", "Count"]];

      for (const category of ratingData.categories) {
        data.push([category.category || "No Category", category.count]);
      }

      const options = {
        title: `${ratingData.rating} Star`,
        titleTextStyle: {
          fontSize: 20,
        },
        pieHole: 0.4,

        is3D: false,
      };

      const chart = (
        <div key={ratingData.rating} className={styles.singleChart}>
        <div className="container-fluid">
          <Chart
            chartType="PieChart"
            width="100%"
            height="250px"
            data={data}
            options={options}
          />
          </div>
        </div>
      );

      rowCharts.push(chart);

      if (rowCharts.length === 2) {
        chartComponents.push(
          <div
            key={`row_${chartComponents.length}`}
            className={styles.chartRow}
          >
            {rowCharts}
          </div>
        );

        // Add a spacer between rows (adjust the height as needed)
        chartComponents.push(
          <div
            key={`spacer_${chartComponents.length}`}
            style={{ height: "20px" }}
          />
        );

        rowCharts = [];
      }
    }

    if (rowCharts.length > 0) {
      chartComponents.push(
        <div key={`row_${chartComponents.length}`} className={styles.chartRow}>
          {rowCharts}
        </div>
      );
    }

    return chartComponents;
  };

  return (
    <div className={styles.chartMain}>
      { loading ? <span>Loading...</span> :
        feedbackData.length > 0 &&
        renderCharts(feedbackData)}
    </div>
  );
}

export default ChartComponent;
