import React, { createContext, useContext, useState } from 'react';

const FeedbackContext = createContext();

export function FeedbackProvider({ children }) {
  const today = new Date();

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  // set default start date to -50years from today.
  const formattedStartDate = `${year - 50}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

  const formattedEndDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;



  const [selectedClients, setSelectedClients] = useState([]);
  const [currentData, setCurrentData] = useState('');
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);

  return (
    <FeedbackContext.Provider
      value={{
        selectedClients,
        setSelectedClients,
        selectedDrivers,
        setSelectedDrivers,
        selectedTrips,
        setSelectedTrips,
        setCurrentData,
        currentData,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
}

export function useFeedback() {
  return useContext(FeedbackContext);
}
