import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/plots";
import { useFeedback } from "../FeedbackContext";
import axios from "axios";

const AverageChartData = ({ count }) => {
  const {
    selectedClients,
    selectedDrivers,
    selectedTrips,
    startDate,
    endDate,
    currentData,
  } = useFeedback();

  const [data, setData] = useState([]);
  const [avg, setAvg] = useState(0);
  console.log(data, "daddadad");
  function createUrlWithIds(ids, key) {
    if (!Array.isArray(ids) || ids.length === 0) {
      return "";
    }

    const queryParams = ids.map((id) => `${key}=${id}`);
    const url = queryParams.join("&");

    return url;
  }

  const fetchData = async () => {
    try {
      const clientsUrl = createUrlWithIds(selectedClients, "clients");
      const driversUrl = createUrlWithIds(selectedDrivers, "drivers");
      const tripTypesUrl = createUrlWithIds(selectedTrips, "tripTypes");

      const response = await axios.get(
        `https://feedback-api.preimo.com/api/feedback/feedbackRatingsData?${clientsUrl}&${driversUrl}&${tripTypesUrl}&startDate=${startDate}&endDate=${endDate}&dateRange=${currentData}`
      );

      const transformedData = response.data.data.map((item) => ({
        date: item._id,
        count: item.averageRating,
      }));
      let sum = transformedData.reduce((total, obj) => total + obj.count, 0);
      let average = sum / transformedData.length;
      setAvg(average);
      setData(transformedData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const config = {
    data,
    xField: "date",
    yField: "count",
    xAxis: {
      range: [0, 1],
      tickCount: 5,
      line: {
        style: {
          stroke: "#333842",
          lineWidth: 4,
        },
      },
    },
    yAxis: {
      line: {
        style: {
          stroke: "#333842",
          lineWidth: 4,
        },
      },
    },
    areaStyle: {
      fill: "l(90) 0:#ffffff 1:#ececec",
    },
    point: {
      size: 4,
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    smooth: true,
  };

  useEffect(() => {
    fetchData(startDate, endDate);
  }, [
    selectedClients,
    selectedDrivers,
    selectedTrips,
    startDate,
    endDate,
    currentData,
  ]);

  return (
    <>
      <div
        style={{
          color: "#333842",
          fontSize: 24,
          fontFamily: "Montserrat",
          fontWeight: "600",
          wordWrap: "break-word",
          paddingBottom: "15px",
        }}
      >
        Average Rating: {Math.round(avg)}
      </div>

      <Line {...config} height={510} width={850} />
    </>
  );
};

export default AverageChartData;
