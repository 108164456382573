import React from 'react';
import {RxCross2} from 'react-icons/rx'

function ShowOptionsTrip({ selectedOptions, selectedTrips, handleRemoveTripSelectedOption, showOption2 }) {
  const styles = {
    chipContainer: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "10px",
      marginLeft:'42px'
    },
    chip: {
      display: "flex",
      alignItems: "center",
      background: "rgb(238, 200, 88)",
      color: "black",
      padding: "4px 8px",
      borderRadius: "20px",
      margin: "5px",
    },
    removeIcon: {
      cursor: "pointer",
      marginLeft: "5px",
      display:'flex',
      paddingTop:'4px'
    },
  };

  return (
    <div>
      <div style={styles.chipContainer}>
        {showOption2.map((selectedOption) => (
          <div key={selectedOption} style={styles.chip}>
            {selectedOption}
            <span
              style={styles.removeIcon}
              onClick={() => handleRemoveTripSelectedOption(selectedOption)}
            >
              <RxCross2 />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ShowOptionsTrip;
