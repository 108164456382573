import React, { useEffect, useRef, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const LineGraph = (selectedCard) => {
  console.log(selectedCard.selectedCard.data.dailyPercentages, 'selectedCard')
  const currentYear = new Date().getFullYear(); // Get current year
  const allMonths = Array.from({ length: 12 }, (_, i) => new Date(currentYear, i).toLocaleString('default', { month: 'short' }));
  const chartRef = useRef(null);
  const [dataPoints, setDataPoints] = useState([]);
  const [graphData, setGraphData] = useState([]); 
  const [selectedIssue, setSelectedIssue] = useState('maximum');
  const [selectedMonth, setSelectedMonth] = useState('This Year');
  const [selectedDataOption, setSelectedDataOption] = useState('This Year');
  const [xAxisLabels, setXAxisLabels] = useState(allMonths.filter(month => !['This Year', 'This Month', 'This Week'].includes(month)));

  const monthlyData = {};

  xAxisLabels.forEach(month => {
    monthlyData[month] = 0;
  });

  Object.entries(selectedCard.selectedCard.data.monthlyPercentages).forEach(([label, value]) => {
    const monthIndex = xAxisLabels.indexOf(label);
    if (monthIndex !== -1) {
      monthlyData[label] = value;
    }
  });

  const newDataPointsWithZero = xAxisLabels.map(month => ({
    label: month,
    y: selectedMonth === 'This Year' ? monthlyData[month] : (month === selectedMonth ? monthlyData[month] : 0),
  }));

  useEffect(() => {
    setDataPoints(newDataPointsWithZero);
    chartRef.current.render();
  }, [selectedCard, selectedMonth]);

  const handleMonthChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedMonth(selectedValue);

    if (selectedValue === 'This Month') {
      const dailyData = selectedCard.selectedCard.data.dailyPercentages;
      const currentMonth = new Date().getMonth(); // Get current month index (0-11)
  
      const selectedMonthData = Object.entries(dailyData)
        .filter(([dateString]) => {
          const date = new Date(dateString);
          return date.getMonth() === currentMonth;
        })
        .reduce((acc, [date, value]) => {
          const shortMonthDate = new Date(date).toLocaleDateString('default', {
            month: 'short',
            day: 'numeric',
          });
          acc[shortMonthDate] = value;
          return acc;
        }, {});
  
      const formattedLabels = Object.keys(selectedMonthData).map(date => {
        const [day, month] = date.split(' '); // Split the date into day and month parts
        return `${month} ${day}`; // Format as 'MMM DD'
      });
     
      setXAxisLabels(formattedLabels);

      const dataPoints = Object.entries(selectedMonthData).map(([label, y]) => ({
        label: label,
        y: y,
        color: y === Math.max(...Object.values(selectedMonthData)) ? '#FA6E6E' : '#8FD7FF',
      }));

      setGraphData(dataPoints);
    } else if (selectedValue === 'This Year') {
      setXAxisLabels(allMonths);
      setDataPoints(newDataPointsWithZero);
    } else if (selectedValue === 'This Week') {
      const weekData = selectedCard.selectedCard.data.percentagesForCurrentWeek;

      const dataPoints = Object.entries(weekData).map(([day, percentage]) => ({
        label: day,
        y: percentage,
        color: percentage === Math.max(...Object.values(weekData)) ? '#FA6E6E' : '#8FD7FF',
      }));

      setXAxisLabels(Object.keys(weekData));
      setDataPoints(dataPoints);
    }

    setSelectedDataOption(selectedValue);
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedIssue(value);
  };

  const getRadioStyle = (issueType) => ({
    backgroundColor: selectedIssue === issueType ? (issueType === 'maximum' ? 'red' : 'blue') : '',
  });

  const options = {
    creditText: '',
    theme: 'light2',
    axisY: {
      suffix: '%',
      minimum: 0,
      maximum: 100,
      interval: 20,
    },
    axisX: {
      interval: 1,
      labelAngle: 0,
      labels: xAxisLabels,
    },
    data: [{
      type: 'line',
      xValueFormatString: '{label}',
      yValueFormatString: '#,##0.00',
      dataPoints: selectedDataOption === 'This Month' ? graphData :
          (selectedDataOption === 'This Week' ? dataPoints : dataPoints),
      color: selectedIssue === 'maximum'
        ? '#FA6E6E'
        : (selectedIssue === 'average' ? '#2A8BEC' : '#2A8BEC'),
    }],
  };

  return (
    <div className='container' style={{ width: '100%', height: 'auto' }}>
      <h1>{selectedCard.selectedCard.category}</h1>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginLeft: '87%', marginTop: '-6%' }}>
          <select style={{ width: '140px', height: '30px', borderRadius:'20px', padding:'4px' }} value={selectedMonth} onChange={handleMonthChange}>
            <option value='This Year'>This Year</option>
            <option value='This Month'>This Month</option>
            <option value='This Week'>This Week</option>
          </select>
        </div>
        {/* <div style={{ marginLeft: '20px', marginTop: '-6%' }}>
          <input
            type='radio'
            id='maximum'
            name='issueType'
            value='maximum'
            checked={selectedIssue === 'maximum'}
            onChange={handleRadioChange}
            style={getRadioStyle('maximum')}
          />
          <label htmlFor='maximum' className={selectedIssue === 'maximum' ? 'selected' : ''}>Maximum Issue</label>

          <input
            type='radio'
            id='average'
            name='issueType'
            value='average'
            checked={selectedIssue === 'average'}
            onChange={handleRadioChange}
            style={getRadioStyle('average')}
          />
          <label htmlFor='average' className={selectedIssue === 'average' ? 'selected' : ''}>Average Issue</label>
        </div> */}
      </div>
      <CanvasJSChart options={options} onRef={ref => (chartRef.current = ref)} />
    </div>
  );
};

export default LineGraph;
