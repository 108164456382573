import React, { useEffect, useState } from "react";
import { Button, Checkbox, Divider, Modal } from "antd";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import { RxCross2 } from "react-icons/rx";
import { userRequest } from "../requestMethod";

const SelectTrip = ({
	setSelectedTrips,
	selectedOptions,
	setSelectedOptions,
	setShowOption2,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [options, setOption] = useState([]);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const toggleOption = (option) => {
		if (selectedOptions.includes(option)) {
			setSelectedOptions(selectedOptions.filter((item) => item !== option));
		} else {
			setSelectedOptions([...selectedOptions, option]);
		}
	};

	useEffect(() => {
		if (options.length < 1) {
			userRequest
				.get("/trip/getAllTripTypes")
				.then((response) => {
					let arr = [];
					if (response.data && response.data.trips) {
						response.data.trips.forEach((val) => {
							arr.push(val.tripType);
						});
					}
					setOption(arr);
				})
				.catch((error) => {
					console.error("Error fetching drivers:", error);
				});
		}
	}, [options]);

	const toggleSelectAll = () => {
		if (selectAll) {
			setSelectedOptions([]);
		} else {
			setSelectedOptions([...options]);
		}
		setSelectAll(!selectAll);
	};

	const handleApplyFilter = () => {
		setIsModalOpen(false);
		setSelectedTrips(selectedOptions);
		setShowOption2(selectedOptions);
	};

	const filteredOptions = options.filter((option) =>
		option.toLowerCase().includes(searchText.toLowerCase())
	);

	const styles = {
		filterContainer: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			boxSizing: "border-box",
		},
		searchBox: {
			width: "100%",
		},
		searchInput: {
			height: "3rem",
			outline: "none",
			border: "1px solid #DCDEE5",
			width: "100%",
			borderRadius: "5px",
			paddingLeft: "30px",
			boxSizing: "border-box",
		},
		selectAllCheckbox: {
			marginTop: "2vh",
			paddingLeft: "2%",
		},
		chekboxStyle: {
			width: "20px",
			height: "20px",
		},
		labelStyle: {
			fontSize: "1.1rem",
			paddingLeft: "2%",
		},
		optionContainer: {
			marginTop: "1vh",
		},
		ulStyle: {
			display: "flex",
			flexDirection: "column",
			listStyleType: "none",
			marginBlockStart: "0",
			marginBlockEnd: "0",
			marginInlineStart: "0",
			marginInlineEnd: "0",
			paddingInlineStart: "2%",
		},
		liStyle: {
			padding: "1vh 0",
			fontSize: "1.1rem",
		},
		buttonStyle: {
			width: "100%",
			padding: "1.8vh 0",
			backgroundColor: "#EEC858",
			border: "none",
			borderRadius: "10px",
			marginTop: "10px",
		},
		buttonStyleOne: {
			// height:'3vh'
		},
	};

	return (
		<>
			<Button type="white" onClick={showModal} style={styles.buttonStyleOne}>
				Trip Type <DownOutlined />
			</Button>
			<Modal
				visible={isModalOpen}
				width={200}
				onCancel={handleCancel}
				footer={null}
				closable={false}
			>
				<div style={styles.filterContainer}>
					<div style={styles.selectAllCheckbox}>
						<Checkbox
							checked={selectAll}
							onChange={toggleSelectAll}
							style={styles.chekboxStyle}
						/>
						<label style={styles.labelStyle}>Select All</label>
					</div>
					<Divider style={{ margin: "5px 0" }} />
					<div style={styles.optionContainer}>
						<ul style={styles.ulStyle}>
							{filteredOptions.map((option) => (
								<li key={option} style={styles.liStyle}>
									<Checkbox
										checked={selectedOptions.includes(option)}
										onChange={() => toggleOption(option)}
										style={styles.chekboxStyle}
									/>
									<label style={styles.labelStyle}>{option}</label>
								</li>
							))}
						</ul>
					</div>
					<button onClick={handleApplyFilter} style={styles.buttonStyle}>
						Apply Filter
					</button>
				</div>
			</Modal>
		</>
	);
};

export default SelectTrip;
