import React from 'react';
import { RxCross2 } from 'react-icons/rx';

function ShowOptions({ selectedOptions, handleRemoveOption, clientOptions, showOption }) {
  const styles = {
    selectedOptionChip: {
      display: "inline-flex",
      background: "rgb(238, 200, 88)",
      color: "black",
      padding: "4px 8px",
      borderRadius: "20px",
      margin: "10px",
    },
    removeOption: {
      marginLeft: "10px",
      cursor: "pointer",
      fontSize: '15px',
      display:'flex',
      paddingTop:'5px'
    },
  };

  return (
    <div className='container-fluid'>
      <div>
        <ul>
          {
            showOption.map((option) => (
              <li key={option} style={styles.selectedOptionChip}>
                {
                  clientOptions.find((client) => client._id === option)?.clientName
                }
                <span onClick={() => handleRemoveOption(option)} style={styles.removeOption}>
                  <RxCross2 />
                </span>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
}

export default ShowOptions;
