import star from "../assets/images/star.png";
import twoStar from "../assets/images/twoStar.png";
import threeStar from "../assets/images/threeStar.png";
import fourStar from "../assets/images/fourStar.png";
import fiveStar from "../assets/images/fiveStar.png";
import { calculateAvgRatings } from "../utils/calculateAvgRatings";

export default function StarRating({ ratings }) {
  const roundedRating = Math.round(calculateAvgRatings(ratings));

  const renderStarImage = () => {
    switch (roundedRating) {
      case 5:
        return <img src={fiveStar} alt="5 star" />;
      case 4:
        return <img src={fourStar} alt="4 star" />;
      case 3:
        return <img src={threeStar} alt="3 star" />;
      case 2:
        return <img src={twoStar} alt="2 star" />;
      case 1:
        return <img src={star} alt="1 star" />;
      default:
        return null; // You can provide a default image or handle it differently
    }
  };

  return (
    <div>
      {renderStarImage()}
      <p
        style={{
          color: "#333842",
          fontSize: 24,
          fontFamily: "Montserrat",
          fontWeight: "500",
          wordWrap: "break-word",
        }}
      >
        {roundedRating}
      </p>
      {/* <p>{roundedRating}</p> */}
      <p
        style={{
          color: "#696973",
          fontSize: 14,
          fontFamily: "Noto Sans",
          fontWeight: "400",
          wordWrap: "break-word",
        }}
      >
        Average Rating
      </p>
    </div>
  );
}
