export const calculateAvgRatings = (ratingsCountAndId) => {
    if (ratingsCountAndId) {

        const sumOfProducts = ratingsCountAndId.reduce((total, item) => total + item._id * item.count, 0);
        const totalCount = ratingsCountAndId.reduce((total, item) => total + item.count, 0);
        const weightedAverage = sumOfProducts / totalCount;
        const roundedAverage = +weightedAverage.toFixed(2);

        return roundedAverage;
    }
    else {
        return 0;
    }
}
